import React from "react";
import { Link } from "react-router-dom";
import "../styles/NoPageFound.css";

const NoPageFound = () => (
  <div className="NoPageFound">
    <p>
      <Link to="/" className="homepage-link">
        404, go back to www.jonathangarcia.com →
      </Link>
    </p>
  </div>
);

export default NoPageFound;
