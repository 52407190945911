import React, { useState, useEffect, useRef } from "react";
import { MdArrowOutward } from "react-icons/md";
import projectDescriptions from "../content/projectDescriptions.json";
import textContent from "../content/textContent.json";
import "../styles/Home.css";

const Home = () => {
  const [activeTab, setActiveTab] = useState(() => {
    return "Jonathan Garcia";
  });

  const [text, setText] = useState("");
  const [isTypingDone, setIsTypingDone] = useState(false);
  const [showLinkedIn, setShowLinkedIn] = useState(true);
  const [showGithub, setShowGithub] = useState(true);
  const [showGoogleScholar, setShowGoogleScholar] = useState(true);
  const [showEmail, setShowEmail] = useState(true);
  const [isFontSizeIncreased, setIsFontSizeIncreased] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const typingIntervalRef = useRef(null);
  const timeoutRef = useRef(null);
  const linkedinLink = process.env.REACT_APP_LINKEDIN_LINK;
  const githubLink = process.env.REACT_APP_GITHUB_LINK;
  const googleScholarLink = process.env.REACT_APP_GOOGLE_SCHOLAR_LINK;
  const resumeLink = process.env.REACT_APP_RESUME_LINK;
  const orderedTabs = ["Jonathan Garcia", "Work/Projects", "Resume"];

  const handleFolderContentClick = () => {
    if (!isTypingDone) {
      clearInterval(typingIntervalRef.current);
      if (Array.isArray(textContent[activeTab])) {
        setText(textContent[activeTab].map((project) => project.name));
      } else if (activeTab !== "Jonathan Garcia") {
        setText(textContent[activeTab] || "");
      }
      setIsTypingDone(true);
    }
  };

  const handleLinkedInClick = () => {
    window.open(linkedinLink, "_blank");
  };

  const handleGithubClick = () => {
    window.open(githubLink, "_blank");
  };

  const handleGoogleScholarClick = () => {
    window.open(googleScholarLink, "_blank");
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:jonathangarcia.cs@gmail.com";
  };

  const handleTabClick = (tabName) => {
    if (activeTab !== tabName) {
      setActiveTab(tabName);
      setIsTypingDone(false);
      setShowLinkedIn(tabName === "Jonathan Garcia");
      setShowGithub(tabName === "Jonathan Garcia");
      setShowGoogleScholar(tabName === "Jonathan Garcia");
      setShowEmail(tabName === "Jonathan Garcia");
      localStorage.setItem("activeTab", tabName);
    }
  };

  const handleResumeClick = () => {
    setActiveTab("Resume");
    localStorage.setItem("activeTab", "Resume");
    window.location.href = resumeLink;
  };
  const handleDownloadResume = () => {
    const anchor = document.createElement("a");
    anchor.href = resumeLink;
    anchor.download = "Jonathan_Garcia_Resume.pdf";
    anchor.click();
  };

  const handleProjectClick = (projectName, projectLink) => {
    if (projectName === "Biometric-based WhatsApp Clone (iOS)") {
      setShowPopup(true);
      setSelectedProject(projectName);

      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setShowPopup(false);
        setSelectedProject("");
      }, 1400);
    } else if (projectLink) {
      window.open(projectLink, "_blank");
    }
  };

  return (
    <div className="home">
      <div className={`finder visible`}>
        <div className="header">
          <div className="tabs-container">
            {orderedTabs.map((tab) => (
              <div
                key={tab}
                className={`tab ${activeTab === tab ? "active" : ""}`}
                onClick={() => handleTabClick(tab)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </div>
            ))}
          </div>
        </div>
        <div className="content">
          <div className="folder-content" onClick={handleFolderContentClick}>
            {activeTab === "Work/Projects" ? (
              textContent[activeTab].map((project, index) => {
                const projectName = project.name;
                const projectDesc = projectDescriptions[projectName];
                const projectLink = project.link;
                const isError = showPopup && selectedProject === projectName;

                return (
                  <div key={projectName}>
                    <p
                      onClick={() =>
                        handleProjectClick(projectName, projectLink)
                      }
                      className="project-desc"
                    >
                      {projectDesc}
                    </p>
                    <p
                      onClick={() =>
                        handleProjectClick(projectName, projectLink)
                      }
                      className={`clickable-text ${isError ? "error" : ""}`}
                    >
                      {isError ? "Private Repository" : projectName}
                      {index === textContent[activeTab].length - 1}
                      <MdArrowOutward className="arrow-icon" />{" "}
                    </p>
                  </div>
                );
              })
            ) : activeTab === "Resume" ? (
              <span className="viewed-at" style={{ marginTop: "5px" }}>
                <span style={{ color: "#555" }}>
                  <span
                    className="resume-link"
                    onClick={handleResumeClick}
                    onMouseEnter={(e) => {
                      e.target.style.color = "#afacac";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "#555";
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    View
                  </span>
                </span>
                {" or"}{" "}
                <span style={{ color: "#555" }}>
                  <span
                    className="resume-link"
                    onClick={handleDownloadResume}
                    onMouseEnter={(e) => {
                      e.target.style.color = "#afacac";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "#555";
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Download
                  </span>
                </span>
              </span>
            ) : (
              <p
                className={`typewriter ${
                  activeTab === "Jonathan Garcia" && isFontSizeIncreased
                    ? "font-increased"
                    : ""
                }`}
              >
                {textContent[activeTab]}
              </p>
            )}
            <div className="social-section">
              {showLinkedIn && activeTab === "Jonathan Garcia" && (
                <div className="linkedin-section" onClick={handleLinkedInClick}>
                  <p className="linkedin-text">LinkedIn</p>
                </div>
              )}
              {showGithub && activeTab === "Jonathan Garcia" && (
                <div className="github-section" onClick={handleGithubClick}>
                  <p className="github-text">GitHub</p>
                </div>
              )}
              {showGoogleScholar && activeTab === "Jonathan Garcia" && (
                <div
                  className="google-scholar-section"
                  onClick={handleGoogleScholarClick}
                >
                  <p className="google-scholar-text">Google Scholar</p>
                </div>
              )}
              {showEmail && activeTab === "Jonathan Garcia" && (
                <div className="email-section" onClick={handleEmailClick}>
                  <p className="email-text">Email</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
